import classNames from 'classnames';
import { memo, useMemo } from 'react';

import { ReactComponent as CrossSVG } from '../../../assets/chip/cross.svg';
import emptyArrowFunc from '../../../helpers/emptyArrowFunc.helper';

const CulturlabsChip = ({
  className = '',
  label,
  onClickCallback = emptyArrowFunc,
  disabled = false,
  showCross = true,
  selected = false,
  mapper = () => label,
}) => {
  const handleClick = () => onClickCallback();

  const buttonClassName = useMemo(
    () =>
      classNames(
        'rounded-[16px] h-[24px] flex flex-row items-center px-[8px]',
        {
          'text-white bg-chip-blue [&>*]:fill-white': selected,
          'text-main-black bg-lightest-blue [&>*]:fill-main-black': !selected,
          'cursor-pointer': !disabled,
        },
        className,
      ),
    [selected, disabled, className],
  );

  return (
    <button disabled={disabled} className={buttonClassName} onClick={handleClick}>
      <span className="font-inter text-s leading-[1]">{mapper(label)}</span>
      {selected && showCross && <CrossSVG />}
    </button>
  );
};

export default memo(CulturlabsChip);
