import { ReactComponent as FlameSVG } from '../../assets/temperature/flame.svg';
import { ReactComponent as SnowflakeSVG } from '../../assets/temperature/snowflake.svg';
import { ReactComponent as TriangleSVG } from '../../assets/triangle.svg';
import useAppStore from '../../store';
import FactorCircleBar from '../FactorCircleBar/FactorCircleBar';

const FactorCard = ({ options }) => {
  const { team, department } = useAppStore(state => state.selectionFilters);

  if (!options) return null;

  const { label, value, url } = options;
  const urlWithParams = `${url}?dept=${department}&team=${team}`;

  return (
    <>
      <a className={getClasses()} href={urlWithParams}>
        <TriangleSVG className="absolute top-[25%]" />
        <div className="absolute top-[24px]">
          <FactorCircleBar options={{ label, value }} />
        </div>
        <div className="absolute w-[240px] bottom-[25%] flex flex-row">
          <SnowflakeSVG />
          <FlameSVG className="ml-auto" />
        </div>
        <h2 className="absolute text-main-black text-xl font-bold w-[200px] text-center">{label}</h2>
      </a>
    </>
  );
};

export default FactorCard;

const getClasses = () =>
  `relative h-[340px] w-[590px] max-w-[calc(50%-12px)] cursor-pointer shadow-temperature-card bg-white flex flex-col justify-center items-center rounded-[24px]`;
