import { useEffect, useState } from 'react';

import DEFAULT_CHIPS_VALUES from '../../constants/DEFAULT_CHIPS_VALUES';
import emptyArrowFunc from '../../helpers/emptyArrowFunc.helper';
import factorsMapper from '../../helpers/factorsMapper.helper';
import APIService from '../../services/API.service';
import InterventionCard from '../InterventionCard/InterventionCard';
import InterventionCardFull from '../InterventionCardFull/InterventionCardFull';
import CulturlabsChip from '../atoms/CulturlabsChip/CulturlabsChip';

const InterventionCataloguePage = () => {
  const [chipsState, setChipsState] = useState(DEFAULT_CHIPS_VALUES);
  const [selectedIntervention, setSelectedIntervention] = useState(null);
  const [interventions, setInterventions] = useState([]);
  const [filteredInterventions, setFilteredInterventions] = useState([]);
  const [factors, setFactors] = useState([]);

  const init = async () => {
    const bearer = localStorage.getItem('token');
    const headers = { Authorization: `Bearer ${bearer}` };
    let factorsWithSubfactors = [];

    await APIService.call({
      method: 'get',
      url: APIService.urls.questionsFactors,
      headers,
      params: {
        populate: '*',
      },
    })
      .catch(emptyArrowFunc)
      .then(({ data }) => {
        const flat = data.data.map(({ id, attributes }) => ({
          id,
          attributes: {
            ...attributes,
            subfactors: attributes.subfactors.data,
          },
        }));

        factorsWithSubfactors = flat;
        setFactors(flat);
      });

    await APIService.call({
      method: 'get',
      url: APIService.urls.interventions,
      headers,
    })
      .catch(emptyArrowFunc)
      .then(({ data }) => {
        const flat = data.data.map(({ id, attributes }) => ({
          id,
          ...attributes,
          factors: {
            ...attributes.factors,
            data: attributes.factors.data.map(factor => {
              const currentFactor = factorsWithSubfactors.find(({ id }) => id === factor.id);
              return {
                ...factor,
                attributes: {
                  ...factor.attributes,
                  subfactors: currentFactor.attributes.subfactors,
                },
              };
            }),
          },
        }));

        setInterventions(flat);
      });
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    filterInterventions();
  }, [chipsState, interventions]);

  const filterInterventions = () => setFilteredInterventions(interventions.filter(filterByCurrentSelection));

  const proceedChipSelection = value => {
    setChipsState(prevState => ({
      ...prevState,
      [value]: !prevState[value],
    }));
  };

  const filterByCurrentSelection = ({ factors: { data } }) =>
    Object.entries(chipsState).some(([factorValue, isEnabled]) =>
      data.some(({ attributes: { value } }) => isEnabled && factorValue === value),
    );

  return (
    <section className="w-full bg-lightgray">
      <div className="flex flex-col w-full  h-[calc(100vh-40px)] p-[48px] overflow-y-auto">
        <h1 className="flex flex-row items-center text-main-black text-xxl font-lato font-bold mb-[32px] capitalize">
          Intervention Catalogue
        </h1>
        <div className="flex flex-row mb-[28px]">
          <span className="font-lato text-semi-xl text-main-black font-[700] mr-[16px]">Tags</span>
          <div className="flex flex-col gap-[8px]">
            <div className="flex min-h-[36px] gap-[8px]">
              {selectedIntervention
                ? selectedIntervention.factors?.data?.map(({ id, attributes: { label } }) => (
                    <CulturlabsChip key={id} className={'h-[36px] rounded-lg'} disabled label={label} />
                  ))
                : factors.map(({ id, attributes: { label, value } }) => (
                    <CulturlabsChip
                      key={id}
                      selected={chipsState[value]}
                      label={label}
                      onClickCallback={() => proceedChipSelection(value)}
                      mapper={() => factorsMapper(factors, value)}
                    />
                  ))}
            </div>
            <div className="flex flex-wrap gap-[8px] min-h-[24px]">
              {selectedIntervention?.factors?.data?.map(factor =>
                factor.attributes.subfactors.map(({ id, attributes: { label } }) => (
                  <CulturlabsChip key={id} className={'rounded-lg'} disabled label={label} />
                )),
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-row flex-wrap justify-between gap-[24px] justify-items-start">
          {selectedIntervention ? (
            <InterventionCardFull intervention={selectedIntervention} close={() => setSelectedIntervention(null)} />
          ) : (
            filteredInterventions.map(intervention => (
              <InterventionCard
                key={intervention.id}
                intervention={intervention}
                click={() => setSelectedIntervention(intervention)}
              />
            ))
          )}
        </div>
      </div>
    </section>
  );
};

export default InterventionCataloguePage;
